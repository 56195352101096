import "./style.css"
import subscription from "../../api/subscription";

function SubBlock(){
    async function subscription_handle(){
        await subscription()
    }

    return(
        <div className="SubBlock">
            <text id="mounthText">1 Месяц</text>
            <text id='price'>100 ₽</text>
            <p id='buyButton' onClick={subscription_handle}>Оплатить</p>
        </div>
    )
}

export default SubBlock;
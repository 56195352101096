import { useEffect, useState } from "react";
import "./style.css";
import register_user from "../../api/register";
import get_keys from "../../api/get_keys";
import Server from "../../components/Server/Server";
import UserComponent from "../../components/UserComponent/UserComponent";
import get_user from "../../api/get_user";
import Loader from "../../components/Loader/Loader";
import SubBlock from "../../components/SubBlock/SubBlock";
import DownloadApps from "../../components/DownloadApps/DownloadApps";

const tg = window.Telegram.WebApp;

function MainPage() {
    const [keys, setKeys] = useState([]);
    const [user, setUser] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const registerAndFetchKeys = async () => {
            try {
                await register_user();
                const user_data = await get_user();
                setUser(user_data);
                const user_keys = await get_keys();
                setKeys(user_keys);
                setLoading(false); // Data has been loaded
            } catch (error) {
                console.error("Error registering user or fetching keys:", error);
                setLoading(false); // Stop loading on error as well
            }
        };

        registerAndFetchKeys();
    }, []);

    const tg_user = tg.initDataUnsafe.user;

    if (loading) {
        return <Loader />;
    }

    return (
        <div className="MainPage">
            <UserComponent user={user} tg_user={tg_user} />
            {user.subscription_status && <SubBlock />}
            <DownloadApps />
            <div className="MainPage-Servers">
                <h4>Шаг 2: Выберите сервер для подключения</h4>
                {keys.map((server_key) => (
                    <Server key={server_key.key_id} server_key={server_key} />
                ))}
            </div>
        </div>
    );
}

export default MainPage;
